import * as React from "react";

import { Link } from "gatsby";

import Layout from "../layouts/master";

const NotFoundPage = () => (
  <Layout>
    <h1>404: Not Found</h1>
    <p>You just hit a page that doesn't exist... the sadness.</p>
    <p><Link to="/">Back home</Link></p>
  </Layout>
)

export default NotFoundPage
